import React from 'react';
import { graphql } from 'gatsby';

import { Layout } from '../layout/Layout';
import Seo from '../components/common/Seo';
import { Banner, DiscoverTechnology, Latest, NewsInformation, SummaryAbout, WhereBuy } from '@/components/modules/home';

const IndexPage = ({ data }) => {
  const { banners, tvs, product_tabs, about_heading, about_text } = data.directus.home;

  return (
    <Layout pageId="home">
      <Banner banners={banners} />
      <Latest tvs={tvs.concat(tvs)} />
      <DiscoverTechnology tabs={product_tabs} />
      <SummaryAbout heading={about_heading} text={about_text} />
      <NewsInformation />
      <WhereBuy />
    </Layout>
  );
};

export const Head = () => <Seo title="Hisense" />;

export const query = graphql`
  query HomePageQuery {
    directus {
      home {
        about_heading
        about_text
        banners(sort: "sort") {
          id
          text
          button_text
          button_link
          link
          text_color
          mobile_text_color
          image {
            id
            title
            imageFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          mobile_image {
            id
            title
            imageFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        tvs {
          id
          name
          buttons
          image {
            id
            title
            imageFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          feature_icons {
            directus_files_id {
              id
              title
              imageFile {
                publicURL
              }
            }
          }
        }
        product_tabs {
          id
          name
          buttons
          tab_name
          tab_icon {
            id
            title
            imageFile {
              publicURL
            }
          }
          image {
            id
            title
            imageFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          mobile_image {
            id
            title
            imageFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          feature_icons {
            directus_files_id {
              id
              title
              imageFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
